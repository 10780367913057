import axios from "axios";
import { useStore } from "@/store/loading.js";
import { useCookies } from "vue3-cookies";
import { showToast } from "@/util/toast";
import router from "@/router";
import { baseURL } from "@/util/base-url-config";

const { cookies } = useCookies();

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    "X-CLIENT-ID": +process.env.VUE_APP_CLIENT_ID,
    "X-CLIENT-SECRET": process.env.VUE_APP_NOT_SECRET_CODE,
  },
});

export const httpClientWithoutLoading = axios.create({
  baseURL: baseURL,
  headers: {
    "X-CLIENT-ID": +process.env.VUE_APP_CLIENT_ID,
    "X-CLIENT-SECRET": process.env.VUE_APP_NOT_SECRET_CODE,
  },
});

httpClientWithoutLoading.interceptors.request.use(
  (config) => {
    const token = cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use(
  (config) => {
    const store = useStore();
    numberOfAjaxCAllPending++;
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    store.mutationer({ isLoading: true });
    const token = cookies.get("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

httpClient.interceptors.response.use(
  (response) => {
    const store = useStore();

    numberOfAjaxCAllPending--;
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    // eslint-disable-next-line eqeqeq
    if (numberOfAjaxCAllPending == 0) {
      store.mutationer({ isLoading: false });
    }
    if (response.data) return response.data;
    else return response;
  },
  (error) => {
    const store = useStore();

    if (numberOfAjaxCAllPending > 0) numberOfAjaxCAllPending--;
    store.mutationer({ isLoading: false });
    store.numberOfAjaxCAllPending = numberOfAjaxCAllPending;
    // toast(error?.response?.data?.message || error?.response?.data?.result?.message || error?.response?.data?.data?.message, toastConfig)
    showToast({
      message:
        error?.response?.data?.message ||
        error?.response?.data?.result?.message ||
        error?.response?.data?.data?.message,
      type: "error",
    });
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      cookies.remove("token");
      router.push({ name: "home" });
    }
    throw error;
  },
);

httpClientWithoutLoading.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    showToast({
      message:
        error?.response?.data?.message ||
        error?.response?.data?.result?.message ||
        error?.response?.data?.data?.message,
      type: "error",
    });
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      cookies.remove("token");
      router.push({ name: "home" });
    }
    throw error;
  },
);

export default httpClient;
