/* eslint-disable no-undef */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store'
import environment from "@/components/Neighborhood/util/environment.js";
import OpenIndicator from "@/components/svg/OpenIndicator.vue";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mitt from "mitt";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "sweetalert2/dist/sweetalert2.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueCookies from "vue3-cookies";
import Vue3Toastify from "vue3-toastify";
import VueGtag from "vue-gtag";
import { VueQueryPlugin } from "@tanstack/vue-query";
import "./assets/css/tailwind.css";
import "./assets/styles/style.scss";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

mapboxgl.accessToken = environment.mapBoxAccessToken;

app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
});

vSelect.props.components.default = () => ({
  OpenIndicator,
});

app.component("v-select-component", vSelect);

app.use(VueSweetalert2);

app.config.errorHandler = function (err, vm, info) {
  console.error("Vue Error:", err, info);
};

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app
  .use(Vue3Toastify, {
    theme: "dark",
    autoClose: 3000,
    icon: false,
    containerClassName: "app-toast",
  })
  // .use(
  //   VueGtag,
  //   {
  //     config: { id: "G-F0J9JN6Y47" },
  //   },
  //   router,
  // )
  .use(VueQueryPlugin)
  .use(pinia)
  .use(router)
  .mount("#app");
